import { Grid, GridItem } from '@chakra-ui/react';

import { Footer } from '../components/Footer';
import { WithCompany } from '../components/WithCompany';
import { SidebarWithHeader } from './SidebarWithHeader';

type CompanyLayoutProps = {
  children: any;
};

export const CompanyLayout: React.FC<CompanyLayoutProps> = ({ children }) => {
  return (
    <WithCompany>
      <SidebarWithHeader isCompany>{children}</SidebarWithHeader>
    </WithCompany>
  );
};
