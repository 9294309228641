import { NextPage, NextPageContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { CompanyLayout } from '../../toolkit/layouts/CompanyLayout';
import { useCompanyQuery } from '../../src/generated/graphql';
import { Heading } from '@chakra-ui/react';

type CompanyProps = {
  companySlug: string;
};

const Company: NextPage<CompanyProps> = ({ companySlug }) => {
  const companyQuery = useCompanyQuery({
    variables: {
      slug: companySlug,
    },
  });

  return (
    <CompanyLayout>
      <Heading>{companyQuery?.data?.company?.name}</Heading>
    </CompanyLayout>
  );
};

export async function getServerSideProps({ query, locale }: NextPageContext) {
  const companySlug = query.companySlug as string;

  return {
    props: {
      companySlug,
      ...(await serverSideTranslations(locale || 'de', ['common'])),
    },
  };
}

export default Company;
